<template>

  <el-dialog
    v-loading="loading"
    :visible="howToModal"
    :before-close="closeModal"
    :close-on-click-modal="false"
    width="60%"
    class="modal"
  >

    <div class="logo-small">
      <MainLogo class="logo" />
    </div>

    <h2 class="header2">How it works</h2>
    <h3 class="header3" style="text-align:center; text-transform:uppercase;">Share your art in 3 easy steps</h3>

    <el-row :gutter="50" class="process">
      <el-col :md="8" :xs="24">
        <div class="big-number">1</div>
        <p>Add your patch to the Virtual Quilt by uploading the original artwork or image you want to share.</p>
      </el-col>      
      <el-col :md="8" :xs="24">
        <div class="big-number">2</div>
        <p>Tag the location where you created the art, share a story behind it, and choose a nonprofit from the drop-down list to select your favorite cause for our <router-link :to="{ name: 'doodlefordollars' }">Doodle for Dollars</router-link> Contest.</p>
      </el-col>      
      <el-col :md="8" :xs="24">
        <div class="big-number">3</div>
        <p>Wait for the tech team to review and approve the content, ensuring that it abides by community standards</p>
      </el-col>      
    </el-row>

    <el-row class="process">
      <el-col class="center">
        <el-button type="primary" @click="startSharing">Start Sharing</el-button>
      </el-col>
    </el-row>

    
  </el-dialog>
</template>

<script>

  import { mapState, mapMutations } from 'vuex'
  
  import MainLogo from '@/assets/Logo.svg';

  export default {

    components: {
      MainLogo
    },

    computed: {
      ...mapState([
        'loading',
        'howToModal'
      ])
    },

    methods: {
      ...mapMutations([
        'setLoading',
        'openFormModal',
        'closeHowToModal'
      ]),

      startSharing: function () {
        this.closeHowToModal()
        this.openFormModal()
      },

      closeModal: function () {
        this.closeHowToModal()
      },

    }
  }
</script>